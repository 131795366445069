<template>
  <div>
    <!-- 第一行 标题 -->
    <el-row>
      <el-col :span="24">
        <div
          class="grid-content center"
          style="
            font-size: 30px;
            color: #606266;
            font-weight: bold;
            letter-spacing: 3px;
            margin-top: 20px;
          "
        >
          评卷客户端管理
        </div>
      </el-col>
    </el-row>
    <!-- 第二行 说明 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content" style="color: #1890ff; font-size: 15px">
          操作说明：1、添加设备 2、设置科目对应设备 3、分配任务 4、缓存任务下发
        </div>
      </el-col>
    </el-row>
    <!-- 第三行 科目数量统计 -->
    <el-row>
      <el-col :span="24">
        <div class="grid-content">
          <div>设置科目对应设备</div>
          <!-- 数量统计 -->
          <div>
            <span
              v-for="item in examSubjectList"
              :key="item.subject"
              style="margin-right: 20px"
              >{{ item.subject | subjectTypeFilters }}:{{
                item.subject === 1
                  ? physicsNum
                  : item.subject === 2
                  ? biologyNum
                  : hemistryNum
              }}个</span
            >
            <!-- <span>物理:{{ physicsNum }}个</span>
            <span style="margin-left: 20px">化学:{{ hemistryNum }}个</span>
            <span style="margin-left: 20px">生物:{{ biologyNum }}个</span> -->
          </div>
          <!-- 切换按钮 -->
          <div style="margin-top: 10px">
            <span
              v-for="item in examSubjectList"
              :key="item.subject"
              :class="[
                'switchBtn',
                tabControl == item.subject ? 'is-selected' : '',
              ]"
              @click="switchSubject(item.subject)"
              >{{ item.subject | subjectTypeFilters }}</span
            >
            <!-- <span
              :class="['switchBtn', tabControl == 1 ? 'is-selected' : '']"
              @click="switchSubject(1)"
              >物理</span
            >
            <span
              :class="['switchBtn', tabControl == 3 ? 'is-selected' : '']"
              @click="switchSubject(3)"
              >化学</span
            >
            <span
              :class="['switchBtn', tabControl == 2 ? 'is-selected' : '']"
              @click="switchSubject(2)"
              >生物</span
            > -->
            <el-button
              v-throttle
              type="primary"
              size="medium"
              style="margin-left: 30px"
              @click="assignTask"
              >分配任务</el-button
            >
            <el-select
              v-model="examQuestionType"
              style="width: 150px; margin-left: 20px"
            >
              <el-option label="混合考题" :value="1"></el-option>
              <el-option label="单一考题" :value="2"></el-option>
            </el-select>
            <el-tooltip class="item" effect="dark" placement="right">
              <i 
              class="el-icon-question" 
              style="margin-left: 12px; font-size: 18px;"
              ></i>
              <div slot="content">
                <div style="width: 200px;margin-bottom: 10px;">1、混合考题：一台阅卷客户端中可能包含科目的多个考题；</div>
                <div style="width: 200px;">2、单一考题：一台阅卷客户端中仅包含科目的一道考题；</div>
              </div>
            </el-tooltip>
          </div>
          <!-- 连接状态 -->
          <div style="margin-top: 15px; display: none">
            <span>连接状态:</span>
            <span style="margin-left: 20px">
              <span
                class="dot"
                style="background-color: #13ce66; margin-top: 8px"
              ></span>
              <span style="margin-left: 10px">已连接</span>
            </span>
            <span style="margin-left: 20px">
              <span
                class="dot"
                style="background-color: #f56c6c; margin-top: 8px"
              ></span>
              <span style="margin-left: 10px">未连接</span>
            </span>
          </div>
          <!-- 全选 -->
          <div style="margin-top: 10px">
            <el-checkbox v-model="allSelect" @change="allSelectFnc()"
              >全选</el-checkbox
            >
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 座位面板 -->
    <el-row :gutter="25" style="margin-left: 20px; margin-right: 20px">
      <el-col
        class="el-col-25"
        v-for="(v, i) in this.seatTotal"
        :key="i"
        style="margin-top: 25px"
      >
        <div class="grid-content seatBlock">
          <el-checkbox
            v-model="checked[i]"
            style="margin-right: 220px; margin-top: 10px; margin-bottom: 10px"
          ></el-checkbox>
          <img src="@/assets/seatPic.png" alt />
          <div
            style="
              color: #000000;
              font-size: 25px;
              font-weight: bold;
              margin-top: 10px;
            "
          >
            {{ v }}
          </div>
        </div>
      </el-col>
      <el-col class="el-col-25" style="margin-top: 25px">
        <div class="grid-content seatBlock" id="seatReNum">
          <span @click="changeNum(1)">-</span>
          <span @click="changeNum(2)">+</span>
        </div>
      </el-col>
    </el-row>

    <!-- 遮罩层-分配中 -->
    <el-dialog
      :visible.sync="dialogLoad"
      width="30%"
      :close-on-click-modal="false"
      :show-close="false"
      :close-on-press-escape="false"
    >
      <span
        style="
          font-size: 50px;
          color: #ffffff;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          line-height: 80px;
          letter-spacing: 2px;
        "
      >
        当前正在分配任务中， 请耐心等待
      </span>
      <img
        src="@/assets/lodingPic.png"
        style="display: block; margin-top: -70px; margin-left: 280px"
        alt
      />
    </el-dialog>
    <!-- 遮罩层-分配完成 -->
    <el-dialog
      :visible.sync="dialogDone"
      width="30%"
      :close-on-click-modal="false"
      :show-close="false"
      :close-on-press-escape="false"
    >
      <span
        style="
          font-size: 50px;
          color: #ffffff;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          line-height: 80px;
          font-weight: 550;
          letter-spacing: 2px;
        "
        >分配任务成功</span
      >
      <img
        src="@/assets/donePic.png"
        style="display: block; margin-top: -70px; margin-left: 320px"
        alt
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  updateClientInfo,
  getClientSubjectApi,
  getClientExamTestApi,
} from "@/api/marking/markingClient.js";
export default {
  data() {
    return {
      //生物、化学、物理
      biologyNum: 0,
      hemistryNum: 0,
      physicsNum: 0,
      //全选
      allSelect: false,
      checked: [],
      //总数
      seatTotal: 21,
      //控制卡片切换
      tabControl: 1,
      //暂存三个科目选中的座位
      biologyChecked: [],
      hemistryChecked: [],
      physicsChecked: [],
      //暂存三个科目所选中座位数组
      terminalCacheStatusList: [],
      dialogLoad: false,
      dialogDone: false,
      examQuestionType: 1,
      examSubjectList: [], // 科目数组，用于展示科目
    };
  },

  mounted() {
    this.setList();
    this.fecthExamSubjectInfo();
  },

  watch: {
    //监听全选
    // allSelect (newState, oldState) {
    //   if (newState == true) {
    //     this.checked.fill(true, 0, this.checked.length)
    //   } else {
    //     this.checked.fill(false, 0, this.checked.length)
    //   }
    //   //监听全选时上方科目统计
    //   var num = 0
    //   for (let i = 0; i < this.checked.length; i++) {
    //     if (this.checked[i] == true) {
    //       num++
    //     }
    //   }
    //   if (this.tabControl == 1) {
    //     this.physicsNum = num
    //   } else if (this.tabControl == 2) {
    //     this.hemistryNum = num
    //   } else {
    //     this.biologyNum = num
    //   }
    // },
    //监听选择，让上方各科目数据统计实时同步
    checked(newState, oldState) {
      var num = 0;
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checked[i] == true) {
          num++;
        }
      }
      //每次发生变化都改变各科目选中数组
      if (this.tabControl == 1) {
        this.physicsNum = num;
        this.physicsChecked = this.checked;
        //如果所有座位都被勾选 则全选为true
        if (!this.checked.includes(false)) {
          this.allSelect = true;
        } else {
          this.allSelect = false;
        }
      } else if (this.tabControl == 3) {
        this.hemistryNum = num;
        this.hemistryChecked = this.checked;
        //如果所有座位都被勾选 则全选为true
        if (!this.checked.includes(false)) {
          this.allSelect = true;
        } else {
          this.allSelect = false;
        }
      } else {
        this.biologyNum = num;
        this.biologyChecked = this.checked;
        //如果所有座位都被勾选 则全选为true
        if (!this.checked.includes(false)) {
          this.allSelect = true;
        } else {
          this.allSelect = false;
        }
      }
    },
  },

  methods: {
    // 获取考试科目信息
    fecthExamSubjectInfo() {
      let nowExamId = Number(this.$route.query.examId);
      getClientSubjectApi(nowExamId).then((res) => {
        // console.log(res, "res");
        if (res.success) {
          const { data } = res;
          this.examSubjectList = data;
          if (this.examSubjectList.length > 0) {
            this.switchSubject(this.examSubjectList[0].subject);
          }
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    //全选
    allSelectFnc() {
      if (this.allSelect == true) {
        this.checked.fill(true, 0, this.checked.length);
      } else {
        this.checked.fill(false, 0, this.checked.length);
      }
      //监听全选时上方科目统计
      var num = 0;
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checked[i] == true) {
          num++;
        }
      }
      if (this.tabControl == 1) {
        this.physicsNum = num;
        this.physicsChecked = this.checked;
      } else if (this.tabControl == 3) {
        this.hemistryNum = num;
        this.hemistryChecked = this.checked;
      } else {
        this.biologyNum = num;
        this.biologyChecked = this.checked;
      }
    },

    //加载页面时处理数组将
    setList() {
      this.seatTotal = Number(this.$route.query.seatNum);
      this.nowExamId = Number(this.$route.query.examId);
      for (let i = 0; i < this.seatTotal; i++) {
        this.checked[i] = false;
        this.physicsChecked[i] = false;
        this.biologyChecked[i] = false;
        this.hemistryChecked[i] = false;
      }
    },
    //改变座位数量
    changeNum(i) {
      if (i == 1) {
        this.seatTotal--;
        // this.checked.length = this.checked.length - 1
        // // this.biologyChecked.length = this.biologyChecked.length - 1
        // if (this.tabControl == 3) {
        //   this.hemistryChecked.length = this.hemistryChecked.length - 1
        //   this.physicsChecked.length = this.physicsChecked.length - 1
        // } else if (this.tabControl == 2) {
        //   this.biologyChecked.length = this.biologyChecked.length - 1
        //   this.physicsChecked.length = this.physicsChecked.length - 1
        // } else {
        //   this.biologyChecked.length = this.biologyChecked.length - 1
        //   this.hemistryChecked.length = this.hemistryChecked.length - 1
        // }
        this.hemistryChecked.pop();
        this.physicsChecked.pop();
        this.biologyChecked.pop();
        this.hemistryNum = this.hemistryChecked.filter((e) => e).length;
        this.biologyNum = this.biologyChecked.filter((e) => e).length;
        this.physicsNum = this.physicsChecked.filter((e) => e).length;
      } else {
        this.seatTotal++;
        if (this.allSelect == true) {
          this.checked.push(true);
          if (this.tabControl == 1) {
            // this.physicsChecked.push(true)
            this.hemistryChecked.push(false);
            this.biologyChecked.push(false);
          } else if (this.tabControl == 3) {
            // this.hemistryChecked.push(true)
            this.physicsChecked.push(false);
            this.biologyChecked.push(false);
          } else {
            // this.biologyChecked.push(true)
            this.physicsChecked.push(false);
            this.hemistryChecked.push(false);
          }
        } else {
          this.checked.push(false);
          if (this.tabControl == 1) {
            // this.physicsChecked.push(false)
            this.hemistryChecked.push(false);
            this.biologyChecked.push(false);
          } else if (this.tabControl == 3) {
            // this.hemistryChecked.push(false)
            this.physicsChecked.push(false);
            this.biologyChecked.push(false);
          } else {
            // this.biologyChecked.push(false)
            this.physicsChecked.push(false);
            this.hemistryChecked.push(false);
          }
        }
      }
    },
    aaa() {
      let a = this.checked.keys();
      for (let x of a) {
        if (this.checked[x] == true) {
          console.log(x);
        }
      }
    },
    //切换选项卡
    switchSubject(val) {
      if (this.tabControl === val) return;
      this.tabControl = val;
      this.allSelect = false;
      if (this.tabControl == 1) {
        this.checked = this.physicsChecked;
      } else if (this.tabControl == 3) {
        this.checked = this.hemistryChecked;
      } else {
        this.checked = this.biologyChecked;
      }
    },
    //处理请求数组
    assignTask() {
      if (
        this.biologyNum == 0 &&
        this.hemistryNum == 0 &&
        this.physicsNum == 0
      ) {
        this.$message({
          message: "请选择设备",
          type: "error",
        });
        return;
      }
      if (
        this.handleServiceNumsOverExamTestNum() &&
        this.examQuestionType === 2
      ) {
        this.$message({
          message: "设备数小于考题数，请添加设备!",
          type: "error",
        });
        return;
      }
      this.$confirm(
        `生物：${this.biologyNum}个设备 化学：${this.hemistryNum}个设备 物理：${this.physicsNum}个设备，考生试卷将会按照每个科目选择的设备进行平均分配，
分配到各设备后将无法修改，确定分配吗？`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.terminalCacheStatusList = [];
          // let appiontDistributionSubjectBiologTestList,
          //   appiontDistributionSubjectChemistryTestList,
          //   appiontDistributionSubjectPhysicsTestList = [];
          // if (this.examQuestionType === 2) {
          //   // 单一考题，随机抽取一个考题
          //   appiontDistributionSubjectPhysicsTestList =
          //     this.randomPickOneExamTestFunc(1);
          //   appiontDistributionSubjectBiologTestList =
          //     this.randomPickOneExamTestFunc(2);
          //   appiontDistributionSubjectChemistryTestList =
          //     this.randomPickOneExamTestFunc(3);
          // }
          for (let i = 0; i < this.seatTotal; i++) {
            let info = {
              examId: this.nowExamId,
              hasSubjectBiologyVideo:
                this.biologyChecked[i] == true ? true : false,
              hasSubjectChemistryVideo:
                this.hemistryChecked[i] == true ? true : false,
              hasSubjectPhysicsVideo:
                this.physicsChecked[i] == true ? true : false,
              terminalNum: i + 1,
              appiontDistributionSubjectBiologTestList:
                this.examQuestionType === 2
                  ? this.randomPickOneExamTestFunc(2, i)
                  : [],
              appiontDistributionSubjectChemistryTestList:
                this.examQuestionType === 2
                  ? this.randomPickOneExamTestFunc(3, i)
                  : [],
              appiontDistributionSubjectPhysicsTestList:
                this.examQuestionType === 2
                  ? this.randomPickOneExamTestFunc(1, i)
                  : [],
            };
            this.terminalCacheStatusList.push(info);
          }

          let clientInfoParam = {
            examId: this.nowExamId,
            terminalCacheStatusList: this.terminalCacheStatusList,
            terminalCount: this.seatTotal,
            taskTestDistributionType: this.examQuestionType,
          };
          console.log(clientInfoParam, "param");
          updateClientInfo(clientInfoParam).then((res) => {
            let { success, msg } = res;
            console.log(res, "res");
            if (success) {
              this.dialogLoad = true;
              setTimeout(() => {
                this.dialogLoad = false;
                this.dialogDone = true;
              }, 2000);
              setTimeout(() => {
                this.dialogDone = false;
                this.$router.push({
                  path: "/marking/markingClient/clientCache",
                  query: { examId: this.nowExamId },
                });
              }, 3000);
            } else {
              this.$message.error(msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消分配",
          });
        });
    },
    // 调用该方法，可以在单一考题下随机抽取一个考题
    // val是科目值
    randomPickOneExamTestFunc(val, index) {
      let result = [];
      this.examSubjectList.forEach((item) => {
        if (item.subject === val) {
          let len = item.subjectTestList.length; // 一共多少条考题 this.seatTotal
          let num = Math.floor(this.seatTotal / len); // 均匀的分量
          if (len == 1) {
            result.push(item.subjectTestList[0].testQuestionId);
          } else {
            let i = Math.floor(index / num);
            result.push(item.subjectTestList[i % len].testQuestionId);
          }
        }
      });
      return result;
    },
    // 该方法判断是否考题数量大于设备数量
    handleServiceNumsOverExamTestNum() {
      let r1 = false,
        r2 = false,
        r3 = false;
      this.examSubjectList.forEach((item) => {
        if (item.subject == 1) {
          r1 = item.subjectTestList.length > this.physicsNum;
        } else if (item.subject == 2) {
          r2 = item.subjectTestList.length > this.biologyNum;
        } else {
          r3 = item.subjectTestList.length > this.hemistryNum;
        }
      });
      let result = r1 || r2 || r3;
      // console.log(result)
      return result;
    },
    getRandomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 调用该方法，过滤是否有缓存视频
    // filterSubjectVideo(terminalCacheStatusList) {
    //   terminalCacheStatusList.forEach((it) => {
    //     if(!it.hasSubjectBiologyVideo) {
    //       it.appiontDistributionSubjectBiologTestList = []
    //     }
    //     if(!it.hasSubjectChemistryVideo) {
    //       it.appiontDistributionSubjectChemistryTestList = []
    //     }
    //     if(!it.hasSubjectPhysicsVideo) {
    //       it.appiontDistributionSubjectPhysicsTestList = []
    //     }
    //   })
    //   return terminalCacheStatusList
    // }
  },
  filters: {
    subjectTypeFilters(key) {
      switch (key) {
        case 1:
          return `物理`;
        case 2:
          return `生物`;
        case 3:
          return `化学`;
        default:
          return ``;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  margin-left: 40px;
  margin-right: 40px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}

.bg-purple {
  background: rgba(223, 230, 236, 0.16);
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.center {
  text-align: center;
}

::v-deep .el-checkbox__inner {
  width: 20px;
  height: 20px;
}

::v-deep .el-checkbox__inner::after {
  border: 2px solid #fff;
  border-left: 0;
  border-top: 0;
  left: 7px;
  top: 3px;
}

::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(50deg) scaleY(1.1);
}

::v-deep .el-dialog {
  background: rgba(0, 0, 0, 0);
  box-shadow: none;
  top: 20%;
  left: 5%;
}
.switchBtn {
  display: inline-block;
  font-weight: bold;
  width: 127px;
  height: 42px;
  background: #dfe6ec;
  border-radius: 10px 10px 0px 0px;
  opacity: 1;
  text-align: center;
  vertical-align: middle;
  line-height: 42px;
  cursor: pointer;
}

.is-selected {
  background: #1886fe;
  box-shadow: 0px 3px 6px 1px #439cfe;
  border-radius: 10px 10px 0px 0px;
  opacity: 0.8;
  color: #ffffff;
}

.dot {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: #999999;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}
.el-col-25 {
  width: 20%;
  text-align: center;
}
.seatBlock {
  display: inline-block;
  width: 90%;
  height: 170px;
  border: 1px solid black;
  img {
    display: block;
    width: 90px;
    height: 72px;
    margin: auto;
  }
}

#seatReNum {
  span {
    display: inline-block;
    width: 100px;
    height: 100px;
    border: 1px dashed #5fb2f7;
    font-size: 70px;
    color: #5fb2f7;
    margin: 10px;
    margin-top: 35px;
    cursor: pointer;
  }
}
</style>
